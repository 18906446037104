/* You can add global styles to this file, and also import other style files */

/*html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
*/

$font-size-base:1rem;

@import 'bootstrap/scss/bootstrap';

@import "@fortawesome/fontawesome-free/css/all.min.css";

@import "@ng-select/ng-select/themes/default.theme.css";

@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";


table {
  &.mat-table {
    width: 100%;
    margin-top: 1rem;
    tr.mat-row, tr.mat-footer-row, tr.mat-header-row {
      height: 30px;
    }
    th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

/**
 * Utilities
 */
.mouse-pointer {
  cursor: pointer;
}

// Add custom css to ng-select for errors.
ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #dc3545;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

// Ngx date picker style
.bs-datepicker-body table td {
  color: #000000;
  font-weight: bold;
  font-size: 1.1em;
}

.bs-datepicker-body table td span.disabled {
  font-weight: normal;
}

.theme-red .bs-datepicker-body table td.week span {
  font-weight: normal;
  font-size: 0.7em !important;
}

.bs-datepicker-body table.days span  {
  width: 40px !important;
  font-size: 1.1em !important;
}


// mat table
.mat-column-createddate {
  width: 180px;
  min-width: 180px !important;
  max-width: 180px !important;
}

.mat-column-date {
  width: 200px;
  min-width: 200px !important;
  max-width: 200px !important;
}

.mat-column-initialseats {
  width: 100px;
  min-width: 100px !important;
  max-width: 100px !important;
  text-align: center !important;
}

.mat-column-seats {
  width: 100px;
  min-width: 100px !important;
  max-width: 100px !important;
  text-align: center !important;
}

.mat-column-totalconfirmedseats {
  width: 100px;
  min-width: 100px !important;
  max-width: 100px !important;
  text-align: center !important;
}

.mat-column-registrationcount {
  width: 100px;
  min-width: 100px !important;
  max-width: 100px !important;
  text-align: center !important;
}

.make-gold {
  background-color: gold !important;
}
